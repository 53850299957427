import { View, Text } from 'react-native'
import React from 'react'
import {
   Link
} from 'react-router-dom'

export default function Landing() {
  return (
    <View>
      <Text>Landing</Text>
      <Link to={'/login'}>Login</Link>
    </View>
  )
}