import React, { useState, useEffect } from 'react'
import { View, Text, Modal } from 'react-native'
import MaterialTable, { Filter } from 'material-table';
import Analytics from 'mcore/lib/Analytics';
import { Alert } from './utils/Alert';
import moment from 'moment';
import {
   Badge,
   Button
} from 'react-native-elements'
// import IssueDetail, { Params as IssueDetailParams } from './IssueDetail'
import { WhereType } from 'firestar';
import { useHistory } from 'react-router-dom';
import { Support } from 'mcore';

const INITIAL_PAGESIZE = 15
const PAGESIZE = 10

export default function BugReports() {

   const [bugReports, setBugReports] = useState<Support.Chat[]>([])
   const [showLoader, setShowLoader] = useState(false)
   const [lastDoc, setLastDoc] = useState<any>()
   const [showChat, setShowChat] = useState<Support.Chat>()
   const [filters, setFilters] = useState<WhereType[]>([])
   const history = useHistory()

   useEffect(() => {
      initialLoad()
   }, [])

   useEffect(() => {
      if (filters.length > 0) {
         initialLoad()
      }
   }, [filters])

   async function initialLoad() {
      setShowLoader(true)
      try {
         const response = await Analytics.getBugReports({
            limit: INITIAL_PAGESIZE,
            where: filters,
            orderBy: {
               fieldPath: 'createdAt',
               directionStr: 'desc'
            }
         })
         setBugReports(response.data)
         console.log('total', response.data.length)
         setLastDoc(response.lastDoc)
      } catch (error) {
         Alert.alert(
            'Error Getting Reports',
            String(error)
         )
         console.log('Error Getting Reports', error)
      }
      setShowLoader(false)
   }

   return (
      <View style={{
         padding: 15
      }}>
         <Modal
            animationType='fade'
            transparent
            visible={showChat != null}>
            <View style={{
               flex: 1,
               alignItems: 'center',
               justifyContent: 'center',
               padding: 20,
               backgroundColor: '#11111160'
            }}>

            </View>
         </Modal>
         <MaterialTable
            isLoading={showLoader}
            title='Bug Reports'
            onChangePage={onChangePage}
            options={{
               pageSizeOptions: [PAGESIZE],
               pageSize: PAGESIZE,
               searchFieldVariant: 'outlined',
               searchFieldStyle: {
                  marginRight: 10,
                  marginTop: 20
               },
               debounceInterval: 400,
               showEmptyDataSourceMessage: true,
               filtering: true
            }}
            onFilterChange={onFilterChange}
            columns={[
               {
                  title: 'ID',
                  field: 'id',
                  headerStyle: { fontWeight: 'bold' },
                  cellStyle: {
                     fontSize: 10
                  },
                  filtering: false,
                  render: (bug) => (
                     <View>
                        {(bug.unread?.support ?? 0) > 0 && <Badge badgeStyle={{
                           backgroundColor: 'red'
                        }} containerStyle={{
                           position: 'absolute',
                           top: -20,
                           right: 0
                        }} value={bug.unread?.support} />}
                        <Text style={{
                           fontSize: 12
                        }}>{bug.id}</Text>
                     </View>
                  )
               },
               {
                  title: 'User',
                  field: 'title',
                  headerStyle: { fontWeight: 'bold' },
                  render: (bug) => (
                     <View>
                        <Text style={{
                           fontSize: 10,
                           color: 'gray'
                        }}>{bug.createdBy?.uid}</Text>
                        <Text>{bug.createdBy?.displayName ?? '--'}</Text>
                        <Text>{bug.createdBy?.email ?? '--'}</Text>
                     </View>
                  ),
                  filtering: false
               },
               {
                  title: 'Title',
                  field: 'title',
                  headerStyle: { fontWeight: 'bold' },
                  filtering: false
               },
               // {
               //    title: 'Description',
               //    field: 'description',
               //    headerStyle: { fontWeight: 'bold' }
               // },
               {
                  title: 'Version',
                  field: 'metadata.platform',
                  headerStyle: { fontWeight: 'bold' },
                  render: (bug) => (
                     <Text>{bug.metadata.platform?.toUpperCase()}, {bug.metadata.version}</Text>
                  ),
                  filtering: false
               },
               {
                  title: 'Created At',
                  render: (error) => moment(error.createdAt.toDate()).format('MMM DD, YYYY'),
                  headerStyle: { fontWeight: 'bold' },
                  filtering: false
               },
               {
                  title: 'Status',
                  field: 'status',
                  headerStyle: { fontWeight: 'bold' },
                  render: (bug) => (
                     <Text style={{
                        backgroundColor: (bug.status ?? 'open') == 'open' ? 'red' : 'gray',
                        color: 'white',
                        paddingVertical: 3,
                        paddingHorizontal: 15,
                        borderRadius: 5
                     }}>{(bug.status ?? 'open').toUpperCase()}</Text>
                  ),
                  filtering: true
               },
               {
                  title: 'Chat',
                  headerStyle: { fontWeight: 'bold' },
                  render: (bug) => (
                     <Button
                        type='clear'
                        titleStyle={{
                           fontSize: 12,
                           fontWeight: 'bold',
                           textAlign: 'center'
                        }}
                        containerStyle={{ width: 60 }}
                        onPress={() => onPressOpenChat(bug.id)}
                        title='SHOW CHAT' />
                  ),
                  filtering: false
               },
            ]}
            onSearchChange={onSearchChange}
            detailPanel={renderDetailPanel}
            data={bugReports} />
      </View>
   )

   function onFilterChange(filters: Filter<Support.Chat>[]) {

      if (filters.length > 0) {
         const filter = filters[0]
         setFilters([
            {
               fieldPath: 'status',
               opStr: '==',
               value: filter.value
            }
         ])
      } else {
         setFilters([])
      }
   }

   function renderDetailPanel(detail: Support.Chat) {
      return (
         <View style={{
            padding: 20
         }}>
            <View>
               <Text>Description</Text>
               <Text style={{
                  marginTop: 3,
                  color: 'gray'
               }}>{detail.description ?? '--'}</Text>
            </View>
            {(detail.status ?? 'open') == 'open' && <Button
               type='outline'
               titleStyle={{
                  fontSize: 12,
                  fontWeight: 'bold',
                  textAlign: 'center'
               }}
               containerStyle={{
                  width: 150,
                  marginTop: 10
               }}
               onPress={() => onCloseIssue(detail.id)}
               title='CLOSE ISSUE' />}
         </View>
      )
   }

   function onPressOpenChat(bugId: string) {
      // setShowChat(bugReports.find(b => b.id == bugId))
      history.push('/bug-reports/'+bugId)
   }

   async function onCloseIssue(bugId: string) {
      setShowLoader(true)
      try {
         const findIndex = bugReports.findIndex(b => b.id == bugId)
         const __bugReports = [...bugReports]
         const response = await Analytics.updateError(bugId, { status: 'closed' })
         const currentBug = {
            ...__bugReports[findIndex],
            ...response
         }
         __bugReports[findIndex] = currentBug
         setBugReports(__bugReports)
      } catch (error) {
         console.log('Error Closing Issue', error)
         Alert.alert('Error Closing Issue', String(error))
      }
      setShowLoader(false)
   }

   function onUpdateIssue(updatedBug: Analytics.MError) {
      const findIndex = bugReports.findIndex(b => b.id == showChat?.id)
      const __bugReports = [...bugReports]
      __bugReports[findIndex] = updatedBug
      setBugReports(__bugReports)
   }

   async function paginateBugReports() {
      if (lastDoc == null) return
      setShowLoader(true)
      try {
         const response = await Analytics.getBugReports({
            limit: PAGESIZE,
            lastDoc: lastDoc,
            orderBy: {
               fieldPath: 'createdAt',
               directionStr: 'desc'
            }
         })
         setLastDoc(response.lastDoc)
         setBugReports([...bugReports, ...response.data])
      } catch (error) {
         Alert.alert(
            'Error Getting Reports',
            String(error)
         )
         console.log('Error Getting Reports', error)
      }
      setShowLoader(false)
   }

   async function searchBugReport(text: string) {
      if (lastDoc == null) return
      setShowLoader(true)
      try {
         const response = await Analytics.getBugReports({
            where: [
               {
                  fieldPath: 'tags',
                  opStr: 'array-contains',
                  value: text.trim().toLowerCase()
               }
            ],
            limit: PAGESIZE
         })
         setBugReports(response.data)
      } catch (error) {
         Alert.alert(
            'Error Searching Reports',
            String(error)
         )
         console.log('Error Searching Reports', error)
      }
      setShowLoader(false)
   }

   function onSearchChange(searchText: string) {
      if (searchText.length == 0) {
         initialLoad()
      } else {
         searchBugReport(searchText)
      }
   }

   function onChangePage(page: number, pageSize: number) {
      if (bugReports.length / pageSize / page <= (INITIAL_PAGESIZE / PAGESIZE)) {
         paginateBugReports()
      }
   }
}
