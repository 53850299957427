"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.durations = exports.plans = void 0;
exports.plans = [
    {
        title: 'Lite',
        description: 'Best for Individuals',
        features: [
            'No Ads',
            'Share project via image',
            'Export KML file',
            'Export GeoJson file',
            'Save unlimited projects',
            'Chat support(English)'
        ],
        id: 'lite',
        skus: [
            'com.logisian.mapulator.lite.weekly',
            'com.logisian.mapulator.lite.monthly',
            'com.logisian.mapulator.lite.yearly',
        ],
    },
    {
        title: 'Pro',
        description: 'Best for Businesses',
        features: [
            'All "Lite" plan features',
            'Share project via link',
            'Search address'
        ],
        id: 'feature',
        skus: [
            'com.logisian.mapulator.feature.monthly',
            'com.logisian.mapulator.feature.yearly',
        ],
    },
];
function stripSKUs(tag, plans) {
    let skus = [];
    plans.forEach((plan) => {
        const __skus = plan.skus.filter((s) => s.includes(tag));
        skus = [...skus, ...__skus];
    });
    return skus;
}
exports.durations = [
    {
        id: 'month',
        title: 'Monthly',
        tag: 'monthly',
        skus: stripSKUs('monthly', exports.plans)
    },
    {
        id: 'year',
        title: 'Yearly',
        skus: stripSKUs('yearly', exports.plans),
        tag: 'yearly'
    },
];
var Upgrades;
(function (Upgrades) {
    async function getPlan(productId, isTesting) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = `https://us-central1-logisian-mapulator-92210.cloudfunctions.net/getPlan?productId=${productId}&isTesting=${isTesting !== null && isTesting !== void 0 ? isTesting : 'false'}`;
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                if (response.ok) {
                    const jsonData = await response.json();
                    resolve(jsonData);
                }
                else {
                    throw {
                        statusText: response.statusText,
                        message: await response.text()
                    };
                }
            }
            catch (error) {
                reject(error);
            }
        });
    }
    Upgrades.getPlan = getPlan;
    async function createSubscription(planId, userInfo, offerId, isTesting) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = 'https://us-central1-logisian-mapulator-92210.cloudfunctions.net/createSubscription';
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        planId,
                        userInfo,
                        offerId,
                        isTesting: isTesting !== null && isTesting !== void 0 ? isTesting : false
                    })
                });
                if (response.ok) {
                    const jsonData = await response.json();
                    resolve(jsonData);
                }
                else {
                    throw {
                        statusText: response.statusText,
                        message: await response.text()
                    };
                }
            }
            catch (error) {
                reject(error);
            }
        });
    }
    Upgrades.createSubscription = createSubscription;
    async function cancelSubscription(subscriptionId, isTesting, immediately) {
        return new Promise(async (resolve, reject) => {
            try {
                const url = 'https://us-central1-logisian-mapulator-92210.cloudfunctions.net/cancelSubscription';
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        subscriptionId,
                        immediately: immediately !== null && immediately !== void 0 ? immediately : false,
                        isTesting: isTesting !== null && isTesting !== void 0 ? isTesting : false
                    })
                });
                if (response.ok) {
                    const jsonData = await response.json();
                    resolve(jsonData);
                }
                else {
                    throw {
                        statusText: response.statusText,
                        message: await response.text()
                    };
                }
            }
            catch (error) {
                reject(error);
            }
        });
    }
    Upgrades.cancelSubscription = cancelSubscription;
})(Upgrades || (Upgrades = {}));
exports.default = Upgrades;
