import React, { useState } from 'react'
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {
   Actions
} from 'firestar'
import ReactJson from 'react-json-view'

export default function Playground() {

   const [path, setPath] = useState('')
   const [result, setResult] = useState<any>()
   return (
      <div style={{
         padding: 20
      }}>
         <Typography variant='h6'>Playground</Typography>
         <Paper>
            <div style={{
               padding: 20,
               marginTop: 10,
               display: 'flex',
               alignItems: 'center',
               flex: 1,
               justifyContent: 'space-between',
               maxWidth: 1400
            }}>
               <TextField 
                  value={path}
                  onChange={(event) => setPath(event.target.value)}
                  style={{ display: 'flex', flex: 1, marginRight: 20 }} 
                  variant='outlined'/>
               <Button 
                  onClick={onClickRun}
                  disableElevation 
                  variant={'contained'}>Run</Button>
            </div>
            <div style={{
               padding: 20
            }}>
               <ReactJson
                  collapsed
                  sortKeys
                  style={{
                     fontSize: 18,
                     flexWrap: 'wrap',
                     wordWrap: "break-word"
                  }} 
                  src={result} />
            </div>
         </Paper>
      </div>
   )

   async function onClickRun(){
      const response = await Actions.readDocument(path)
      setResult(response)
   }
}
