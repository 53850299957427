"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const firestar_1 = require("firestar");
var Projects;
(function (Projects) {
    async function getAppSettings() {
        return firestar_1.Actions.readDocument('settings/app');
    }
    Projects.getAppSettings = getAppSettings;
})(Projects || (Projects = {}));
exports.default = Projects;
