"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const firestar_1 = require("firestar");
const collectionPath = 'assets';
var Assets;
(function (Assets) {
    function get(id) {
        return firestar_1.Actions.readDocument(`assets/${id}`);
    }
    Assets.get = get;
    function getAll(descriptor) {
        return firestar_1.Actions.readCollection(collectionPath, descriptor);
    }
    Assets.getAll = getAll;
    function create(document) {
        return firestar_1.Actions.addToCollection(collectionPath, document);
    }
    Assets.create = create;
    function update(id, document) {
        return firestar_1.Actions.updateDocument(`assets/${id}`, document);
    }
    Assets.update = update;
    function uploadAsset(assetId, data, suffix) {
        return firestar_1.Storage.uploadFileDataToPath(`assets/${assetId}/thumbnail${suffix !== null && suffix !== void 0 ? suffix : ''}.webp`, data, 'data_url', {
            contentType: 'image/webp',
            cacheControl: 'public,max-age=10000000',
            customMetadata: {
                access: 'public'
            }
        });
    }
    Assets.uploadAsset = uploadAsset;
    function uploadImage(assetId, data) {
        return firestar_1.Storage.uploadFileDataToPath(`assets/${assetId}/originalImage.png`, data, 'data_url', {
            contentType: 'image/png',
            cacheControl: 'public,max-age=10000000'
        });
    }
    Assets.uploadImage = uploadImage;
    function getSearchObject(user) {
        const response = firestar_1.Actions.appendSearchTags(user, {
            splitKeys: [
                'name'
            ],
            createMatches: true,
            includeDocumentId: true,
            includeSpecialCharacters: true,
            absoluteKeys: []
        });
        return response;
    }
    Assets.getSearchObject = getSearchObject;
})(Assets || (Assets = {}));
exports.default = Assets;
