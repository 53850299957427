import React, { useState, useEffect } from 'react'
import { View, Text } from 'react-native'
import MaterialTable, { Column } from 'material-table';
import { Alert } from './utils/Alert';
import moment from 'moment';
import Users, {
   User,
   PurchaseOptions,
   subscriptionSkus,
   PlatformOptions
} from 'mcore/lib/Users';
import { Button, SearchBar } from 'react-native-elements';
import {
   WhereType
} from 'firestar'
import { Upgrades } from 'mcore';
import { useDebounce } from './utils';

const INITIAL_PAGESIZE = 15
const PAGESIZE = 10

export default function BugReports() {

   const [users, setUsers] = useState<User[]>([])
   const [showLoader, setShowLoader] = useState(false)
   const [lastDoc, setLastDoc] = useState<any>()
   const [searchText, setSearchText] = useState('')
   const debouncedSearchTerm = useDebounce(searchText, 1000);
   const [premiumUsers, setPremiumUsers] = useState(false)
   const [platform, setPlatform] = useState<'ios' | 'android' | 'all'>('all')

   function Columns() : Column<User>[]{
      return [
         {
            title: 'Name',
            field: 'displayName',
            headerStyle: { fontWeight: 'bold' },
            render: (user) => (
               <View>
                  <Text style={{
                     fontSize: 10,
                     color: 'gray'
                  }}>{user.uid}</Text>
                  <Text>{user?.displayName ?? '--'}</Text>
                  <Text>{user?.email ?? '--'}</Text>
               </View>
            ),
         },
         {
            title: 'Notification',
            render: (user) => user.token ? 'Available' : 'Unavailable',
            headerStyle: { fontWeight: 'bold' },
            field: 'email'
         },
         {
            title: 'Region',
            headerStyle: { fontWeight: 'bold' },
            field: 'deviceInfo.country'
         },
         {
            title: 'Type',
            render: (user) => (
               <View style={{
                  alignItems: 'center'
               }}>
                  <View style={{
                     marginBottom: 5
                  }}>
                     {user.purchase != null ? (
                        <View>
                           <Text style={{
                              textAlign: 'center',
                              fontSize: 12
                           }}>{(PurchaseOptions as any)[user.purchase?.productId ?? 'free']} • {PlatformOptions[user.purchase?.platform]}</Text>
                           {subscriptionSkus.includes(user.purchase?.productId) && (
                              <Button
                                 titleStyle={{
                                    fontSize: 12,
                                    fontWeight: 'bold'
                                 }}
                                 type='clear'
                                 onPress={() => onPressValidate(user, true)}
                                 title='VALIDATE' />
                           )}
                        </View>
                     ) : (
                        <Text style={{
                           fontSize: 12
                        }}>{user.membershipType.toUpperCase()}</Text>
                     )}
                  </View>
               </View>
            ),
            headerStyle: { 
               fontWeight: 'bold',
               textAlign: 'center'
            }
         },
         {
            title: 'Device',
            render: (user) => {
               return (
                 <div>
                   {user?.deviceInfo != null ? <div>
                     <div>
                       {user?.deviceInfo?.OS?.name} • 
                       {user?.deviceInfo?.OS?.version}
                     </div>
                     <div>
                       v{user?.deviceInfo?.application?.version}(
                       {user?.deviceInfo?.application?.buildNumber})
                     </div>
                   </div> : <div>NA</div>}
                 </div>
               );
            },
            headerStyle: { fontWeight: 'bold' }
         },
         {
            title: 'Update At',
            render: (error) => {
               const date = error.updatedAt.toDate()
               return (
                  <div>
                     <div>{moment(date).fromNow()}</div>
                     <div>{moment(date).format('MMM DD, YYYY')}</div>
                  </div>
               )
            },
            headerStyle: { fontWeight: 'bold' }
         },
         {
            title: 'Created At',
            render: (error) => {
               const date = error.createdAt.toDate()
               return (
                  <div>
                     <div>{moment(date).fromNow()}</div>
                     <div>{moment(date).format('MMM DD, YYYY')}</div>
                  </div>
               )
            },            
            headerStyle: { fontWeight: 'bold' }
         },
      ]
   }
   useEffect(
      () => {
         // Make sure we have a value (user has entered something in input)
         if (debouncedSearchTerm) {
            // Set isSearching state
            //  setIsSearching(true);
            //  // Fire off our API call
            //  searchCharacters(debouncedSearchTerm).then(results => {
            // 	// Set back to false since request finished
            // 	setIsSearching(false);
            // 	// Set results state
            // 	setResults(results);
            //  });
            fetchUsers(false, searchText)
         } else {
            fetchUsers(false, '')
         }
      },
      // This is the useEffect input array
      // Our useEffect function will only execute if this value changes ...
      // ... and thanks to our hook it will only change if the original ...
      // value (searchTerm) hasn't changed for more than 500ms.
      [debouncedSearchTerm]
   );

   useEffect(() => {
      fetchUsers(false, searchText)
   }, [premiumUsers, platform])

   async function fetchUsers(isPaginating: boolean, text: string) {
      setShowLoader(true)
      try {
         const where = [] as WhereType[]
         if (platform !== 'all') {
            where.push({
               fieldPath: 'purchase.platform',
               opStr: '==',
               value: platform
            })
         }
         if (premiumUsers) {
            where.push({
               fieldPath: 'membershipType',
               opStr: 'in',
               value: ['monthly', 'yearly', 'feature']
            })
         }
         if(text.trim().length > 0){
            where.push(               {
               fieldPath: 'tags',
               opStr: 'array-contains',
               value: text.trim().toLowerCase()
            })
         }
         const response = await Users.getUsers({
            orderBy: {
               fieldPath: 'createdAt',
               directionStr: 'desc'
            },
            where: where,
            limit: INITIAL_PAGESIZE,
            lastDoc: isPaginating ? lastDoc: undefined
         })
         if(isPaginating){
            setUsers([...users, ...response.data])
         }else{
            setUsers(response.data)
         }
         setLastDoc(response.lastDoc)
      } catch (error) {
         Alert.alert(
            'Error Getting Reports',
            String(error)
         )
         console.log('Error Getting Reports',error)
      }
      setShowLoader(false)
   }

   return (
      <View style={{
         padding: 15,
         paddingTop: 30
      }}>
         <View style={{
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
         }}>
            <Button 
               buttonStyle={{
                  paddingHorizontal: '2em'
               }}
               onPress={onPressRefresh}
               title='Refresh'/>
            <View style={{
               flexDirection: 'row',
               alignItems: 'center'
            }}>
               <div>
                  <select onChange={(event) => setPlatform(event.target.value as any)} value={platform}>
                     <option value={'all'}>Both</option>
                     <option value='ios'>iOS</option>
                     <option value='android'>Android</option>
                  </select>
                  <label>&nbsp;Platform</label>
               </div>
               &nbsp;
               <div>
                  <input onChange={() => {
                     setPremiumUsers(!premiumUsers)
                  }} checked={premiumUsers} type={'checkbox'} />
                  <label>&nbsp;Premium Users</label>
               </div>
               &nbsp;
               <button onClick={() => {
                  setPlatform('all')
                  setPremiumUsers(false)
               }}>Clear</button>
            </View>
         </View>
         <br/>
         <View style={{
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: "space-between"
         }}>
            <View />
            <SearchBar
               value={searchText}
               inputStyle={{
                  color: 'black'
               }}
               placeholder='Search with keywords'
               onChangeText={(value) => setSearchText(value)}
               containerStyle={{
                  backgroundColor: 'clear',
                  borderTopWidth: 0,
                  borderBottomWidth: 0,
                  width: 350,
               }}
               inputContainerStyle={{
                  backgroundColor: '#dddddd',
                  borderRadius: 25
               }} />
         </View>
         <MaterialTable 
            title='Users'
            isLoading={showLoader}
            options={{
               pageSizeOptions: [PAGESIZE],
               pageSize: PAGESIZE,
               actionsColumnIndex: Columns().length - 1,
               searchFieldVariant: 'outlined',
               searchFieldStyle: {
                  marginRight: 10,
                  marginTop: 20
               },
               debounceInterval: 400,
               showEmptyDataSourceMessage: true,
               search: false
            }}
            detailPanel={renderDetail}
            onSearchChange={onSearchChange}
            onChangePage={onChangePage}
            columns={Columns()}
            data={users}/>
      </View>
   )

   function renderDetail(user:User){
      return (
         <div style={{
            padding: '1em'
         }}>
            <button onClick={() => onPressDelete(user)}>Delete</button>
         </div>
      )
   }

   async function onPressDelete(user: User) {

      Alert.alert('Are you sure?', `${user.displayName ?? '--'} - ${user.email ?? '--'}`, [
         {
            text: 'Yes',
            onPress: async () => {
               onConfirmDelete(user)
            },
            style: 'destructive'
         },
         {
            text: 'Cancel',
            style: 'cancel'
         }
      ])
   }

   async function onConfirmDelete(user: User) {
      setShowLoader(true)
      try {
         console.log(`Deleting ${user.displayName ?? '--'} - ${user.email ?? '--'}`)
         await Users.__deleteAccount(user.id)
         fetchUsers(false, searchText)
      } catch (error) {
         setShowLoader(false)
         Alert.alert('Error Deleting User', String(error))
         console.log('Error Deleting User:', error)
      }
   }

   function onSearchChange(searchText:string){
      fetchUsers(false, searchText)
   }

   async function paginateUsers() {
      if(lastDoc == null) return 
         fetchUsers(true, searchText)
   }

   function onChangePage(page: number, pageSize: number){
      if(users.length/pageSize/page <= (INITIAL_PAGESIZE/PAGESIZE)){
         paginateUsers()
      }
   }

   async function onPressValidate(user:User, showAlert: boolean){

      return new Promise<{
         status?: Users.SubsciptionStatus,
         error?: any
      }>(async (resolve, reject) => {
         const purchase = user.purchase
         setShowLoader(true)
         try {

            const response = await Users.getPurchaseStatus({
               ...purchase,
               subscription: true
            }, Users.getUserInfo(user))

            const components = [] as string[]
            const result = response as Upgrades.PurchaseValidation
            console.log('Validation Status: ', result)

            components.push('Status: ' + result.status)

            if (result.originalPurchaseDate) {
               const date = new Date(result.originalPurchaseDate)
               components.push('Orignal Purchase Date: ' + moment(date).format('DD-MM-YYYY hh:mm a'))
            }

            if (result.purchaseDate) {
               const date = new Date(result.purchaseDate)
               components.push('Purchase Date: ' + moment(date).format('DD-MM-YYYY hh:mm a'))
            }

            if (result.expirationDate) {
               const date = new Date(result.expirationDate)
               components.push('Expiration Date: ' + moment(date).format('DD-MM-YYYY hh:mm a'))
            }

            if (result.willRenew) {
               components.push('Will Renew: ' + String(result.willRenew))
            }
            resolve({
               status: result.status,
               error: result.error
            })
            Alert.alert('Validation', components.join('\n'))

         } catch (error) {
            console.log('Error Validating', error, user)
            if (showAlert == true) {
               Alert.alert('Error Validating' + String(error))
            }
            reject(error)
         }
         setShowLoader(false)
      })
   }

   async function onPressRefresh(){
      fetchUsers(false, searchText)
   }

}
