import React from 'react'
import { View, ActivityIndicator, Text } from 'react-native'
import Backdrop from '@material-ui/core/Backdrop';

export default function Progress() {
   return (
      <View style={{
         flex: 1,
         justifyContent: 'center',
         alignItems: 'center',
         zIndex: 10000
      }}>
         <Backdrop style={{
            background: '#11111180',
            zIndex: 1
         }} open={true} >
            <Text></Text>
            <ActivityIndicator/>
         </Backdrop>         
      </View>
   )
}
