import React from 'react'
import { View, Text } from 'react-native'

export default function AppSettings() {
   return (
      <View>
         <Text>AppSettings</Text>
      </View>
   )
}
