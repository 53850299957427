"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const firestar_1 = __importStar(require("firestar"));
var Projects;
(function (Projects) {
    async function getProjectWithId(projectId) {
        return (await firestar_1.Actions.readCollectionGroup('projects', {
            where: [
                {
                    fieldPath: 'id',
                    opStr: '==',
                    value: projectId
                }
            ]
        })).data[0];
    }
    Projects.getProjectWithId = getProjectWithId;
    function getDuplicateProjects(userId, name) {
        return firestar_1.Actions.readCollection(`users/${userId}/projects`, {
            where: [
                {
                    fieldPath: 'name',
                    opStr: '==',
                    value: name.trim()
                }
            ]
        });
    }
    Projects.getDuplicateProjects = getDuplicateProjects;
    function getProjects(userId, descriptor) {
        return firestar_1.Actions.readCollection(`users/${userId}/projects`, Object.assign(Object.assign({}, descriptor), { orderBy: {
                fieldPath: 'updatedAt',
                directionStr: 'desc'
            } }));
    }
    Projects.getProjects = getProjects;
    function createProject(userId, sessionGroup) {
        return firestar_1.Actions.addToCollection(`users/${userId}/projects`, sessionGroup);
    }
    Projects.createProject = createProject;
    function writeProject(userId, sessionGroup, batch) {
        return firestar_1.Actions.writeDocument(`users/${userId}/projects`, sessionGroup, batch);
    }
    Projects.writeProject = writeProject;
    function updateProject(userId, projectId, sessionGroup) {
        return firestar_1.Actions.updateDocument(`users/${userId}/projects/${projectId}`, sessionGroup);
    }
    Projects.updateProject = updateProject;
    function deleteProject(userId, id) {
        return firestar_1.Actions.deleteDocument(`users/${userId}/projects/${id}`);
    }
    Projects.deleteProject = deleteProject;
    function searchLibraries(searchText, descriptor) {
        return firestar_1.Actions.readCollection('libraries', Object.assign({ where: [
                {
                    fieldPath: 'tags',
                    opStr: 'array-contains-any',
                    value: searchText.trim().toLowerCase()
                }
            ] }, descriptor !== null && descriptor !== void 0 ? descriptor : {}));
    }
    Projects.searchLibraries = searchLibraries;
    function isProjectOwner(sessionGroup) {
        var _a;
        if ((_a = sessionGroup.createdBy) === null || _a === void 0 ? void 0 : _a.uid) {
            const userId = firestar_1.default.getLoginId();
            if (userId) {
                return sessionGroup.createdBy.uid == userId;
            }
            else {
                return false;
            }
        }
        else {
            return true;
        }
    }
    Projects.isProjectOwner = isProjectOwner;
})(Projects || (Projects = {}));
exports.default = Projects;
