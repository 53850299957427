import {
	initializeApp
} from 'firebase/app'

export const firebaseConfig = {
	apiKey: "AIzaSyCDbaWrAry0mlFstCBoePnDKBlqcRgDcRI",
	authDomain: "logisian-mapulator-92210.firebaseapp.com",
	databaseURL: "https://logisian-mapulator-92210.firebaseio.com",
	projectId: "logisian-mapulator-92210",
	storageBucket: "logisian-mapulator-92210.appspot.com",
	messagingSenderId: "923634062989",
	appId: "1:923634062989:web:81111472a84d45000974c4",
	measurementId: "G-BRZ02LB4M0",
};

const app = initializeApp(firebaseConfig)

export {
	app
}